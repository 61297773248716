import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import { useDispatch, useSelector } from 'react-redux'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import toast from 'react-hot-toast'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import ReactCodeInput from 'react-verification-code-input'
import { userActions } from '_actions'
import { userService } from '_services'

const LoginIllustrationWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100vh',
  '@keyframes cf3FadeInOut': {
    '0%': {
      opacity: 1,
    },
    '20%': {
      opacity: 1,
    },
    '34%': {
      opacity: 0,
    },
    '88%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  '@-webkit-keyframes cf3FadeInOut': {
    '0%': {
      opacity: 1,
    },
    '20%': {
      opacity: 1,
    },
    '34%': {
      opacity: 0,
    },
    '88%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  '& img': {
    animation: 'cf3FadeInOut 24s infinite',
  },
  '& #screen1': {
    animationDelay: '16s',
  },
  '& #screen2': {
    animationDelay: '8s',
  },
  '& #screen': {
    animationDelay: '0s',
  },
}))

const LoginIllustration = styled('img')(({ theme }) => ({
  position: 'absolute',
  objectFit: 'cover',
  top: 0,
  left: 0,
  width: '100%',
  height: '100vh',
}))

const RightWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100vh',
  overflow: 'auto',
  [theme.breakpoints.up('md')]: {
    maxWidth: 400,
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: 460,
  },
}))

const BoxWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('md')]: {
    maxWidth: 400,
  },
}))

const StyledLink = styled(Link)(({ theme }) => ({
  color: '#666CFF',
  textDecoration: 'none',
}))

const ReactCodeInputWrapper = styled(Box)(({ theme }) => ({
  '& > div > div': {
    display: 'flex',
    justifyContent: 'center',
  },
  '& input': {
    backgroundColor: 'transparent',
    color: `${theme.palette.text.primary} !important`,
    fontFamily: theme.typography.body1.fontFamily,
    border: `1px solid rgba(${theme.palette.primary.main}, 0.22) !important`,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    caretColor: `${theme.palette.text.primary} !important`,
    borderRadius: '8px !important',
    marginLeft: '2px',
    marginRight: '2px',
    width: '40px !important',
    '&:focus, &:focus-visible': {
      outline: 0,
      borderWidth: 2,
      border: `2px solid ${theme.palette.primary.main} !important`,
    },
  },
}))

function LoginPage() {
  const [inputs, setInputs] = useState({
    username: '',
    password: '',
  })
  const [submitted, setSubmitted] = useState(false)
  const { username, password } = inputs
  const loggingIn = useSelector((state) => state.authentication.loggingIn)
  const requestTwoStep = useSelector(
    (state) => state.authentication.requestTwoStep,
  )
  const twoStepData = useSelector((state) => state.authentication.twoStepData)

  const dispatch = useDispatch()

  // reset login status
  useEffect(() => {
    dispatch(userActions.logout())
  }, [])

  function handleChange(e) {
    const { name, value } = e.target
    setInputs((inputs) => ({ ...inputs, [name]: value }))
  }

  function handleSubmit(e) {
    e.preventDefault()

    setSubmitted(true)
    if (username && password) {
      // get return url from location state or default to home page
      const from = window?.history?.state?.from || '/'
      dispatch(userActions.login(username, password, from))
    }
  }

  function verifyTwostep(code) {
    dispatch(userActions.verifyTwostep(code, twoStepData?.resendToken))
  }

  function cancelTwoStep() {
    dispatch(userActions.cancelTwoStep())
  }

  async function resendTwoStep() {
    const ret = await userService.resendTwostepCode(twoStepData?.resendToken)
    if (ret) {
      console.log('ret')
      toast.success('Sent verification code again', {
        duration: 2000,
      })
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        overflowX: 'hidden',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LoginIllustrationWrapper>
          <LoginIllustration
            id="screen1"
            src="/images/Screen1.png"
            layout="fill"
            objectFit="cover"
          />
          <LoginIllustration
            id="screen2"
            src="/images/Screen2.png"
            layout="fill"
            objectFit="cover"
          />
          <LoginIllustration
            id="screen3"
            src="/images/Screen3.png"
            layout="fill"
            objectFit="cover"
          />
        </LoginIllustrationWrapper>
      </Box>
      <RightWrapper>
        <Box
          sx={{
            p: 7,
            pb: 10,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'background.paper',
          }}
        >
          <BoxWrapper>
            <img
              src="/images/1k-logo.svg"
              style={{ margin: '16px auto', display: 'block', width: 96 }}
            />
            <Box>
              <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
                {'Howdy partner! 👋🏻'}
              </Typography>
              <Typography variant="body2" sx={{ mb: 2, textAlign: 'center' }}>
                {'Welcome to the place where all the magic happens.'}
              </Typography>
              <Box
                sx={{
                  textAlign: 'center',
                  borderRadius: 1,
                  backgroundColor: 'rgba(16, 17, 33, 0.60)',
                  p: 4,
                  mb: 10,
                }}
              >
                <Typography variant="body1">
                  {'New to the partner portal?'}
                  {' '}
                  <StyledLink href="/register">{'Apply for an account'}</StyledLink>
                </Typography>
              </Box>
              <form name="form" onSubmit={handleSubmit}>
                <Box sx={{ mb: 2 }}>
                  <TextField
                    id="username"
                    name="username"
                    label="Email/Username"
                    variant="outlined"
                    onChange={handleChange}
                    style={{ width: '100%' }}
                  />
                </Box>
                <Box sx={{ mb: 2 }}>
                  <TextField
                    id="password"
                    name="password"
                    label="Password"
                    variant="outlined"
                    onChange={handleChange}
                    style={{ width: '100%' }}
                    type="password"
                  />
                </Box>
                <Box
                  sx={{
                    mb: 3,
                    p: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <FormControlLabel
                    label="Remember Me"
                    control={<Checkbox name="remember-me" />}
                  />
                  <StyledLink href="/forget-password">
                    {'Forgot Password?'}
                  </StyledLink>
                </Box>
                {/* <div className="form-group">
                    <input type="text" name="username" placeholder="Email/Username" value={username} onChange={handleChange} className={'form-control' + (submitted && !username ? ' is-invalid' : '')}/>
                    <label>Email/Username</label>
                    {submitted && !username &&
                        <div className="invalid-feedback">Username is required</div>
                    }
                </div> */}
                {/* <div className="form-group">
                    <label>Password</label>
                    <input type="password" name="password" placeholder="Password" value={password} onChange={handleChange} className={'form-control' + (submitted && !password ? ' is-invalid' : '')}/>
                    {submitted && !password &&
                        <div className="invalid-feedback">Password is required</div>
                    }
                </div> */}
                <Box sx={{ mb: 3 }}>
                  <Button variant="outlined" fullWidth type="submit">
                    {loggingIn && (
                      <span className="spinner-border spinner-border-sm mr-1" />
                    )}
                    {'Login'}
                  </Button>
                </Box>
              </form>
            </Box>
          </BoxWrapper>
        </Box>
      </RightWrapper>
      <Dialog onClose={cancelTwoStep} open={requestTwoStep || false}>
        <DialogTitle>{'Two-step Authentication'}</DialogTitle>
        <Typography
          variant="body1"
          sx={{
            mx: 5,
            mb: 3,
            color: '#FFFFFF',
            maxWidth: '430px',
          }}
        >
          {'Enter the authentication code that we sent to a number ending in'}
          {' '}
          {twoStepData?.last4}
          {' '}
          {'below:'}
        </Typography>
        <ReactCodeInputWrapper
          sx={{
            px: 5,
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ReactCodeInput onComplete={(res) => verifyTwostep(res)} />
        </ReactCodeInputWrapper>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 5,
            height: '100%',
          }}
        >
          <Typography
            onClick={resendTwoStep}
            variant="subtitle2"
            sx={{
              mr: 5,
              cursor: 'pointer',
              color: '#666CFF',
            }}
          >
            {'Resend Code'}
          </Typography>
          <Typography
            onClick={cancelTwoStep}
            variant="subtitle2"
            sx={{
              ml: 5,
              cursor: 'pointer',
              color: '#FFFFFF',
            }}
          >
            {'Cancel'}
          </Typography>
        </Box>
      </Dialog>
    </Box>
  )
}

export default LoginPage
